import { useMemo } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useColors } from '../../hooks/useColors';
import theme from '../../common/theme';
import { useScale } from '../../hooks/useScale';

const { height } = Dimensions.get('window');

const SettingsScreenStyles = () => {
  const { scale } = useScale();
  const { colors } = useColors();
  const insets = useSafeAreaInsets();

  return useMemo(
    () =>
      StyleSheet.create({
        screen: {
          flex: 1,
          height: height,
        },
        header: {
          padding: scale(16),
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: colors.teritiary2,
          paddingTop: scale(16 + insets.top),
        },
        buttons: {
          flexDirection: 'row',
        },
        title: {
          width: scale(400),
          height: scale(46),
          padding: scale(4),
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: theme.radius.m,
          backgroundColor: colors.teritiary3,
        },
        titleText: {
          color: colors.white,
          fontSize: scale(14),
          lineHeight: scale(22),
          paddingVertical: scale(8),
          fontFamily: theme.fonts.medium,
        },
        scrollView: {
          height: height - 78,
        },
        content: {
          alignItems: 'center',
          backgroundColor: colors.elevation0,
        },
        section: {
          width: scale(540),
          marginTop: scale(40),
        },
        subSection: {
          marginLeft: scale(20),
          marginBottom: scale(8),
        },
        sectionTitle: {
          fontSize: scale(14),
          color: colors.textLight,
          marginBottom: scale(12),
          paddingHorizontal: scale(14),
        },
        footnote: {
          width: scale(540),
          alignItems: 'center',
          paddingVertical: scale(80),
        },
        footnoteText: {
          opacity: 0.2,
          color: colors.text,
          fontSize: scale(10),
          lineHeight: scale(12),
          marginBottom: scale(10),
          textTransform: 'uppercase',
        },
      }),
    [colors, insets, scale],
  );
};

export default SettingsScreenStyles;
