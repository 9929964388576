import React, { useMemo } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { DocketItemStatus, DocketStatus } from '@oolio-group/domain';
import { useSession } from '../../../hooks/useSession';
import { useInvokeWithDelay } from '../../../hooks/useInvokeWithDelay';
import DocketDisplayItemIcon from './DocketDisplayItemIcon';
import DocketItemStyles from '../DocketItem.styles';
import { formatQuantityString } from '../../../utils/general';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { allDayViewHighlightedItemsVar } from '../../../state/apolloVars';

export interface DocketDisplayItemProps {
  id: string;
  status: DocketStatus;
  itemStatus: DocketItemStatus;
  name: string;
  note?: string;
  quantity: number;
  variant?: string;
  modifiers?: string[];
  onPressDocketItem: () => Promise<void>;
}

const DocketDisplayItemComponent: React.FC<DocketDisplayItemProps> = ({
  id,
  status,
  itemStatus,
  name,
  note,
  variant,
  quantity,
  modifiers,
  onPressDocketItem,
}) => {
  const styles = DocketItemStyles();
  const { session } = useSession();
  const { loading: itemActionLoading, invokeFunction: invokeItemAction } =
    useInvokeWithDelay({ callBack: onPressDocketItem });

  const highlightedItems = useReactiveVar(allDayViewHighlightedItemsVar);
  const isHighlighted =
    status === DocketStatus.CREATED &&
    (itemStatus === DocketItemStatus.CREATED ||
      itemStatus === DocketItemStatus.RECALLED) &&
    highlightedItems.includes(id);

  const getTextStyle = useMemo(() => {
    const nameStyle = isHighlighted ? styles.nameHighlighted : styles.name;
    const textStyle: TextStyle[] = [nameStyle];

    switch (itemStatus) {
      case DocketItemStatus.VOIDED:
        textStyle.push(styles.voidedItem);
        break;
      case DocketItemStatus.BUMPED:
        if (status == DocketStatus.CREATED) {
          textStyle.push(styles.preppedItem); // Prepped
        }
        break;
      case DocketItemStatus.REBUMPED:
        if (status == DocketStatus.CREATED) {
          textStyle.push(styles.completedItem); // Completed
        }
        break;
      case DocketItemStatus.SERVED:
        if (status == DocketStatus.CREATED) {
          textStyle.push(styles.servedItem); // Served
        }
        break;
      default:
        break;
    }

    return textStyle;
  }, [isHighlighted, itemStatus, status, styles]);

  const getItemStyle = useMemo(() => {
    const itemStyle: ViewStyle[] = [styles.product];

    if (isHighlighted) {
      itemStyle.push(styles.productHighlighted);
    }

    return itemStyle;
  }, [isHighlighted, styles]);

  const statusesToBlurDocketItem = [
    DocketItemStatus.SERVED,
    DocketItemStatus.REBUMPED,
  ];
  if (!session?.rebumpToggle)
    statusesToBlurDocketItem.push(DocketItemStatus.BUMPED);

  const shouldShowLoading =
    itemStatus == DocketItemStatus.CREATED ||
    itemStatus == DocketItemStatus.RECALLED ||
    itemStatus == DocketItemStatus.BUMPED ||
    itemStatus == DocketItemStatus.SERVED ||
    DocketItemStatus.REBUMPED;

  return (
    <TouchableOpacity
      testID="item"
      onPress={invokeItemAction}
      style={getItemStyle}
    >
      <View style={styles.line}>
        <View style={[styles.qty, isHighlighted && styles.qtyHighlighted]}>
          <Text style={[styles.qtyText, getTextStyle]}>
            {formatQuantityString(quantity)}
          </Text>
        </View>
        <Text numberOfLines={2} style={getTextStyle}>
          {name}
        </Text>
        <DocketDisplayItemIcon
          status={status}
          itemStatus={itemStatus}
          bumpItemLoading={shouldShowLoading && itemActionLoading}
        />
      </View>
      <View>
        {!!note ? (
          <View style={styles.line}>
            <Text numberOfLines={3} style={styles.note}>{`▶ ${note}`}</Text>
          </View>
        ) : null}
        {!!variant ? (
          <View style={styles.line}>
            <Text
              style={[
                styles.modifier,
                isHighlighted && styles.modifierHighlighted,
              ]}
            >
              {variant}
            </Text>
          </View>
        ) : null}
        {modifiers?.length
          ? modifiers.map((modifier, i) => (
              <View key={name + modifier + i} style={styles.line}>
                <Text
                  style={[
                    styles.modifier,
                    isHighlighted && styles.modifierHighlighted,
                  ]}
                >{`↳ ${modifier}`}</Text>
              </View>
            ))
          : null}
      </View>
    </TouchableOpacity>
  );
};

export default DocketDisplayItemComponent;
