import { Dimensions, StyleSheet } from 'react-native';
import theme from '../../common/theme';

const { height: vh, width: vw } = Dimensions.get('window');

const styles = StyleSheet.create({
  screenContainer: {
    width: vw,
    height: vh,
  },
  screen: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    padding: 40,
    alignItems: 'center',
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
    overflow: 'hidden',
  },
  btnBack: {
    position: 'absolute',
    top: 30,
    left: 40,
  },
  content: {
    width: '100%',
  },
  title: {
    flex: 1,
    fontSize: 16,
    lineHeight: 22,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  search: {
    width: 620,
    marginTop: 40,
    marginBottom: 20,
  },
  loading: {
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  table: {
    maxHeight: vh - 300,
  },
  group: {
    marginBottom: 20,
  },
  rowParent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  rowChild: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  textParent: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
  },
  textChild: {
    flex: 1,
    marginLeft: 10,
  },
  emptyText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
  deviceText: {
    flex: 1,
    paddingVertical: 10,
  },
  deviceInfoText: {
    fontSize: 13,
    lineHeight: 16,
    marginTop: 4,
  },
  printerLabel: {
    lineHeight: 20,
    marginRight: 10,
    textAlign: 'right',
    color: theme.colors.red,
  },
});

export default styles;
