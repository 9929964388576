import { App as AppType } from '@oolio-group/domain';
import { Tracer } from '@oolio-group/tracer-client';
import { AppRegistry, Platform } from 'react-native';
import { getVersion } from 'react-native-device-info';
import { enableScreens } from 'react-native-screens';

require('react-native-gesture-handler');
enableScreens();

const App = require('./App').default;

AppRegistry.registerComponent('OolioKDS', () => App);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('OolioKDS', {
    rootTag: document.getElementById('root'),
  });
}

const getTracingLocalStorageData = async () => {
  return {};
};
Tracer.getInstance(
  {
    devMode: __DEV__, // dev mode
    platform: Platform.OS,
    app: AppType.KITCHEN_DISPLAY_APP,
    environment: process.env['REACT_APP_LOGGING_ENV'] || process.env.NODE_ENV, // local, test-in, staging-x, prod-green
    dsn: 'https://8764e248cca248e3855dc8b1d7279c57@o314552.ingest.sentry.io/4504094594433024', // metric data go to pos-app or cds-app,...
    version: getVersion(),
    enableFetchTracking: true, // track none graphql request
  },
  getTracingLocalStorageData,
);
if (!__DEV__) {
  Tracer.wrap(App);
}

export { App };
