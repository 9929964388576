/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client/react/hooks';
import { App, KitchenDisplay, Store } from '@oolio-group/domain';
import { useCallback } from 'react';
import { useDeviceId } from './useDeviceId';
import { useSession } from './useSession';
import { useNavigation } from '@react-navigation/native';
import * as sessionPreferences from '../state/preferences';
import {
  LOGIN_KDS,
  LOGOUT_KDS,
  REFRESH_KDS_TOKEN as REFRESH_KDS_TOKEN_MUTATION,
} from '../graphql/auth';
import config from '../config';
import { setItem } from '../storage/interface';
import {
  ORDER_TYPES_STORAGE_KEY,
  SALES_CHANNEL_STORAGE_KEY,
  ORG_TIMINGS_STORAGE_KEY,
} from '../common/constants';
import { StackNavigationProp } from '@react-navigation/stack';
import { noopHandler } from '../utils/general';
import * as Sentry from '@sentry/react-native';

export const useAuth = () => {
  const { deviceId: kdsDeviceUuid } = useDeviceId();
  const { setSession } = useSession();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const onCompleteLoginRequest = useCallback(
    (data: any) => {
      if (data) {
        if (data?.kdsLoginByDeviceCode) {
          const session: sessionPreferences.Session = {
            token: data.kdsLoginByDeviceCode.appToken,
            activeApp: App.KITCHEN_DISPLAY_APP,
            authorized: true,
            device: {
              id: data.kdsLoginByDeviceCode.deviceId,
            },
            currentVenue: {
              id: data.kdsLoginByDeviceCode.venue,
            },
            currentStore: data.kdsLoginByDeviceCode.store,
            currentOrganization: {
              id: data.kdsLoginByDeviceCode.organization,
            },
            kitchenDisplay: data.kdsLoginByDeviceCode.kitchenDisplay,
            localeCode: config.DEFAULT_LOCALE,
          };
          setSession(session);
          setItem(SALES_CHANNEL_STORAGE_KEY, []);
          setItem(ORDER_TYPES_STORAGE_KEY, []);
          setItem(ORG_TIMINGS_STORAGE_KEY, undefined);
          navigation.reset({ routes: [{ name: 'MainScreen' }] });
        }
      }
    },
    [navigation, setSession],
  );

  const [loginRequest, loginResponse] = useMutation<{
    kdsLoginByDeviceCode: {
      appToken: string;
      store: Store;
      organization: string;
      venue: string;
      deviceId: string;
      kitchenDisplay: KitchenDisplay;
    };
  }>(LOGIN_KDS, {
    onCompleted: onCompleteLoginRequest,
    onError: noopHandler,
  });

  const [logoutRequest, logoutResponse] = useMutation<{}>(LOGOUT_KDS, {
    onError: noopHandler,
  });

  const logout = useCallback(
    async (kdsId: string) => {
      try {
        await logoutRequest({
          variables: {
            input: {
              id: kdsId,
            },
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [logoutRequest],
  );

  const login = useCallback(
    async (code: string) => {
      loginRequest({
        variables: {
          input: {
            deviceCode: code,
            deviceUuid: kdsDeviceUuid,
          },
        },
      });
    },
    [loginRequest, kdsDeviceUuid],
  );

  const [refreshTokenRequest] = useMutation<{ kdsRefreshToken: string }>(
    REFRESH_KDS_TOKEN_MUTATION,
  );

  const refreshToken = useCallback(
    async (kdsId: string) => {
      try {
        const resposne = refreshTokenRequest({
          variables: {
            input: {
              id: kdsId,
            },
          },
        });
        return resposne;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [refreshTokenRequest],
  );

  return {
    login,
    loginResponse,
    logoutResponse,
    logout,
    refreshToken,
  };
};
