import React, { FC } from 'react';
import {
  Text,
  ViewStyle,
  TouchableOpacity,
  ActivityIndicator,
  TextStyle,
} from 'react-native';
import { useColors } from '../../hooks/useColors';
import ButtonStyles from './Buttons.styles';
import Icon from '../Icon/Icon';

interface ButtonTextProps {
  testID: string;
  label: string;
  loading?: boolean;
  disabled?: boolean;
  onPress: () => void;
  type?: 'positive' | 'negative' | 'interface' | 'active';
  containerStyle?: ViewStyle | ViewStyle[];
  ignoreTheme?: boolean;
  icon?: string;
}

const ButtonText: FC<ButtonTextProps> = ({
  testID,
  type,
  label,
  loading,
  disabled = false,
  onPress,
  containerStyle,
  ignoreTheme,
  icon,
}) => {
  const styles = ButtonStyles();
  const { colors } = useColors();

  const getContainerStyle = (type?: string) => {
    const style: ViewStyle[] = [styles.container];

    switch (type) {
      case 'positive':
        style.push({ backgroundColor: colors.green });
        break;
      case 'negative':
        style.push({ backgroundColor: colors.red });
        break;
      case 'interface':
        style.push({
          backgroundColor: ignoreTheme ? colors.teritiary3 : colors.elevation2,
        });
        break;
      case 'active':
        style.push({ backgroundColor: colors.white });
        break;
      case 'disabled':
        style.push({ opacity: 0.3 });
        break;
      default:
        return style;
    }

    return style;
  };

  const getTextStyle = (type?: string) => {
    const style: TextStyle[] = [styles.text];

    switch (type) {
      case 'active':
        style.push({ color: colors.black1 });
        break;
      case 'interface':
        style.push({ color: colors.white });
        break;
      case 'active':
        style.push({ color: colors.white });
        break;
      default:
        style.push({ color: colors.text });
        return style;
    }

    return style;
  };

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress}
      disabled={disabled || loading}
      style={[getContainerStyle(type), containerStyle]}
    >
      {!loading ? (
        <>
          <Text style={getTextStyle(type)}>{label}</Text>
          {icon && (
            <Icon
              name={icon}
              size={20}
              color={type === 'active' ? colors.black1 : colors.white}
              style={styles.icon}
            />
          )}
        </>
      ) : (
        <ActivityIndicator color={colors.textInverted} />
      )}
    </TouchableOpacity>
  );
};

export default ButtonText;
