import { getHeaders } from './fetchHeaders';
import * as Sentry from '@sentry/react-native';

type JSONValue = string | number | boolean | JSONObject | JSONArray;
interface JSONObject {
  [x: string]: JSONValue;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface JSONArray extends Array<JSONValue> {}

export async function makeRequest(url: string, input: JSONObject) {
  try {
    const body = JSON.stringify(input);
    const headers = await getHeaders();
    const response = await fetch(url, {
      method: 'POST',
      body: body,
      headers: headers,
    });
    return await response.json();
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    return {};
  }
}
